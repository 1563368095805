import * as React from 'react';

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { FAQ_INFO } from './pricingPackages';

export default function FAQAccordion() {
    return (
        <Stack sx={{ margin: { xs: '80px 8px', md: 10 } }} >

            <Typography variant='h5' align='center' marginBottom={2}>
                Често задавани въпроси?
            </Typography>
            {
                FAQ_INFO.map(x =>
                    <Accordion key={x.id} >
                        <AccordionSummary
                            expandIcon={<ExpandCircleDownIcon sx={{ color: 'white' }} />}
                            id={x.id}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'primary.light',
                                }
                            }}
                        >
                            <Typography>{x.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{x.details}</Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            }
        </Stack>
    )
};
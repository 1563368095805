import { Typography, Box } from "@mui/material";
import * as React from 'react';
import PricingCard from "../components/PricingPlansPage/PricingCard";
import FAQAccordion from "../components/PricingPlansPage/FAQAccordion";
import MainContentStructure from "../components/NavigationBar/MainContentStructure";
import standardPackages from "../components/PricingPlansPage/pricingPackages";

export default function PricingList() {
    const [selectedValue, setSelectedValue] = React.useState('Безплатен');
    const handleChange = (event) => { setSelectedValue(event.currentTarget.value); };

    return (
        <MainContentStructure>
            <Box
                sx={{
                    margin: '0 auto',
                    paddingTop: 1,
                }}
            >
                <Typography variant='h4' align='center' marginBottom={5}>
                    Ценови лист
                </Typography>
                {
                    standardPackages.map((card) => <PricingCard
                        key={card.title}
                        card={card}
                        handleChange={handleChange}
                        selectedValue={selectedValue}
                        isInfoPage={true}
                    />)
                }
                <FAQAccordion />
            </Box>
        </MainContentStructure>
    )
}